import React, { useState } from "react";
import Address from "../users/address";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import Input from "../styled/input";
import Checkbox from "../styled/checkbox";
import { MdReportProblem } from "react-icons/md";
import { Address as IAddress, List, BuyerList } from "../../__generated__/graphql";
import Loader from "./loader";
import { useTranslation } from "react-i18next";

export interface BuyerEditable {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  organisation: string;
  taxNumber: string;
  lists: BuyerList[];
  addresses: IAddress[];
}

const defaultUser: BuyerEditable = {
  firstName: "",
  lastName: "",
  email: "",
  organisation: "",
  telephone: "",
  taxNumber: "",
  lists: [],
  addresses: []
};

export default function CreateUser({
  onCreate,
  isCreatingUser,
  lists
}: {
  onCreate: (buyer: BuyerEditable, createEshopAccount: boolean) => void;
  isCreatingUser: boolean;
  lists: List[];
}) {
  const [user, setUser] = useState<BuyerEditable>({ ...defaultUser });
  const [createEshopAccount, setCreateEshopAccount] = useState(true);
  const { t } = useTranslation();

  const handleSubmitUser = async (e: any) => {
    e.preventDefault();
    await onCreate(user, createEshopAccount);
  };

  const handleDetailsChange = (e: any) => {
    const userToEdit = JSON.parse(JSON.stringify(user));
    userToEdit[e.target.name] = e.target.value;
    setUser(userToEdit);
  };

  const handleAddAddress = () => {
    user.addresses.push({ type: "billing" });
    setUser({ ...user });
  };

  const handleAddressUpdate = async (index: number, name: string, value: any) => {
    const addressToUpdate = user.addresses[index];
    // @ts-ignore
    addressToUpdate[name] = value;
    setUser({ ...user });
  };

  const handleRemoveAddress = async (index: number) => {
    if (index === undefined) return;
    user.addresses.splice(index, 1);
    setUser({ ...user });
  };

  const handleListSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { lists } = user;
    const index = lists.findIndex(l => l.ref === e.target.value);
    if (index !== -1) {
      lists.splice(index, 1);
    } else {
      lists.push({ ref: e.target.value } as any);
    }
    setUser({ ...user });
  };

  return (
    <div id="userCreate">
      <form onSubmit={handleSubmitUser} id="userCreateForm">
        <div className="createUserDetails">
          <Input
            label={t("Email")}
            required
            name="email"
            value={user.email || ""}
            type="email"
            onChange={handleDetailsChange}
            autoComplete="off"
            placeholder={t("Email")}
          />
          <Input
            label={t("Phone")}
            name="telephone"
            type="string"
            value={user.telephone || ""}
            autoComplete="off"
            onChange={handleDetailsChange}
            placeholder={t("Phone")}
          />
        </div>
        <div className="createUserDetails">
          <Input
            label={t("First name")}
            required
            name="firstName"
            type="string"
            value={user.firstName || ""}
            autoComplete="off"
            onChange={handleDetailsChange}
            placeholder={t("First name")}
          />
          <Input
            label={t("Last name")}
            required
            name="lastName"
            type="string"
            value={user.lastName || ""}
            autoComplete="off"
            onChange={handleDetailsChange}
            placeholder={t("Last name")}
          />
        </div>
        <div className="createUserDetails">
          <Input
            label={t("Organization")}
            name="organisation"
            type="string"
            value={user.organisation || ""}
            autoComplete="off"
            onChange={handleDetailsChange}
            placeholder={t("Organization")}
          />
          <Input
            label={t("Tax ID")}
            name="taxNumber"
            type="string"
            value={user.taxNumber || ""}
            autoComplete="off"
            onChange={handleDetailsChange}
            placeholder={t("Tax ID")}
          />
        </div>
        {lists && lists.length ? (
          <div id="addToList">
            <p>{t("Add to lists")}:</p>
            <hr />
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: "var(--gutter)" }}>
              {lists
                ? lists.map(l => (
                    <Checkbox
                      label={l.title}
                      key={l._id}
                      name="list"
                      value={l._id}
                      onChange={handleListSelect}
                      checked={!!user.lists.find(userList => userList.ref === l._id)}
                    />
                  ))
                : null}
            </div>
          </div>
        ) : null}
        <div className="addresses">
          {user.addresses &&
            user.addresses.map((a, index) => (
              <Address key={index} index={index} removeAddress={handleRemoveAddress} handleChange={handleAddressUpdate} address={a} />
            ))}
        </div>
        <hr />
        {user.addresses && user.addresses.length < 2 ? (
          <div>
            <ButtonV2 variant="secondary" type="button" onClick={handleAddAddress}>
              {t("Add an address")}
            </ButtonV2>
          </div>
        ) : null}
        <hr />
        <Checkbox
          onChange={() => setCreateEshopAccount(!createEshopAccount)}
          label={t("Create an Eshop account and send an email receipt")}
          checked={createEshopAccount}
        />
        <small>
          <MdReportProblem />
          {createEshopAccount
            ? t("Customer will be required to reset their password on first login")
            : t("Customer is required to create an account from the Eshop in order to login")}
        </small>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <span />
          <ButtonV2 variant="primary" disabled={isCreatingUser || !user.email} type="submit">
            {isCreatingUser ? <Loader /> : t("Submit")}
          </ButtonV2>
        </div>
      </form>
    </div>
  );
}
