import { gql } from "../../__generated__/gql";

/* ITEM ID SHOULD BE OF LONG TYPE */

// TODO: switch to inventoryFilters input
export const GET_INVENTORY = gql(`
  query inventory($page: Int, $limit: Int, $stock: String, $type: String, $status: String, $discogsStatus: String, $hasSnippet: String, $sort: String, $order: String, $supplierCode: [String], $condition: [String], $location: [String], $locationSearch: String, $categories: [String], $labels: [String], $itemId: Float, $term: String, $from: String, $to: String, $dateFilter: String, $secondHand: Boolean, $preOrder: Boolean) {
    inventory(page: $page, limit: $limit, stock: $stock, type: $type, status: $status, discogsStatus: $discogsStatus, hasSnippet: $hasSnippet, sort: $sort, order: $order, supplierCode: $supplierCode, condition: $condition, location: $location, locationSearch: $locationSearch, categories: $categories, labels: $labels, itemId: $itemId, term: $term, from: $from, to: $to, dateFilter: $dateFilter, secondHand: $secondHand, preOrder: $preOrder) {
      pagination {
        limit
        pages
        page
      }
      stats {
        total
        stockCount
        listingsCount
      }
      entries {
        ...ItemFields
      }
    }
  }
`);

export const GET_INVENTORY_METADTA = gql(`
  query inventoryMetadata($filters: InventoryFilters) {
    inventoryMetadata(filters: $filters) {
      stats {
        total
        stockCount
        listingsCount
      }
      pagination {
        limit
        page
      }
    }
  }
`);

export const GET_INVENTORY_LOCATIONS = gql(`
  query inventoryLocations($offset: Int, $term: String) {
    inventoryLocations(offset: $offset, term: $term) {
      entries
      pagination {
        hasMore
      }
    }
  }
`);

// TODO: switch to inventoryFilters input
export const GET_INVENTORY_AS_CATALOGUE = gql(`
  query inventoryAsCatalogue($page: Int, $limit: Int, $artists: [Float], $type: String, $sort: String, $order: String, $labels: [String], $styles: [String], $genres: [String]) {
    inventory(page: $page, limit: $limit type: $type, artists: $artists, sort: $sort, order: $order, labels: $labels, styles: $styles, genres: $genres) {
      pagination {
        limit
        pages
        page
      }
      entries {
        ...ItemFields
      }
    }
  }
`);

export const GET_ITEM = gql(`
  query item($id: Long, $listingRef: ID) {
    item(id: $id, listingRef: $listingRef) {
      ...ItemFields
    }
  }
`);

export const GET_ITEM_STATS_FOR_IDS = gql(`
  query itemStatsForIds($ids: [Long!]!) {
    itemStatsForIds(ids: $ids) {
      entries {
        id
        listingsCount
        audioPreviewsCount
        createdAt
        createdBy
      }
    }
  }
`);

export const GET_ITEMS = gql(`
  query items($ids: [Long]!) {
    items(ids: $ids) {
      ...ItemFields
    }
  }
`);

export const GET_ITEM_SEARCH = gql(`
  query itemSearch($term: String!, $type: String, $quantitySort: Boolean, $stocked: Boolean) {
    itemSearch(term: $term, type: $type, quantitySort: $quantitySort, stocked: $stocked) {
      entries {
        ...ItemFields
      }
    }
  }
`);

gql(`
  fragment BookSearchResultEntryFields on ItemBookSearchResultsEntry {
    title
    subtitle
    description
    publisher
    identifiers {
      type
      value
    }
    authors
    language
    pageCount
    images {
      uri
    }
    thumb
  }
`);

export const GET_ITEM_BOOK_SEARCH = gql(`
  query itemBookSearch($term: String!) {
    itemBookSearch(term: $term) {
      google {
        ...BookSearchResultEntryFields
      }
      isbndb {
        ...BookSearchResultEntryFields
      }
    }
  }
`);

export const GET_ITEM_FROM_DATABASE = gql(`
  query itemFromDatabase($discogsId: Int, $creatorsId: String) {
    itemFromDatabase(discogsId: $discogsId, creatorsId: $creatorsId) {
      ...ItemFields
    }
  }
`);

export const POST_ITEM_UPDATE = gql(`
  mutation itemUpdate(
    $itemRef: ID!, 
    $descriptions: ItemInputDescriptions, 
    $itemUpdateReleaseInput: ItemReleaseInput, 
    $itemUpdateProductInput: ItemProductInput, 
    $itemUpdateBookInput: ItemBookInput) {
      itemUpdate(
        itemRef: $itemRef, 
        descriptions: $descriptions, 
        itemUpdateProductInput: $itemUpdateProductInput, 
        itemUpdateReleaseInput: $itemUpdateReleaseInput, 
        itemUpdateBookInput: $itemUpdateBookInput) {
          ...ItemFields
      }
  }
`);

export const POST_ITEM_UPDATE_FROM_DISCOGS = gql(`
  mutation itemUpdateFromDiscogs($itemRef: ID!) {
    itemUpdateFromDiscogs(itemRef: $itemRef) {
      ...ItemFields
    }
  }
`);

export const POST_ITEM_IMAGE_URL = gql(`
  mutation itemImageSignedUrl($itemRef: ID!, $filename: String!) {
    itemImageSignedUrl(itemRef: $itemRef, filename: $filename) {
      key
      signedUrl
    }
  }
`);

export const POST_ITEM_IMAGE_REGISTER = gql(`
  mutation itemImageRegister($itemRef: ID!, $filename: String!, $key: String!, $alternative: Boolean!, $index: Int) {
    itemImageRegister(itemRef: $itemRef, filename: $filename, key: $key, alternative: $alternative, index: $index) {
      ...ItemFields
    }
  }
`);

export const POST_ITEM_CREATE_SNIPPET_SIGNED_URL = gql(`
  mutation itemSnippetSignedUrlGenerate($releaseId: Long!, $filename: String!) {
    itemSnippetSignedUrlGenerate(releaseId: $releaseId, filename: $filename) {
      key
      signedUrl
    }
  }
`);

export const POST_ITEM_CREATE_SNIPPET = gql(`
  mutation itemSnippetCreate($releaseId: Long!, $filename: String!, $key: String!, $position: String!) {
    itemSnippetCreate(releaseId: $releaseId, filename: $filename, key: $key, position: $position) {
      uri
      message
    }
  }
`);

export const POST_ITEM_SEO_UPDATE = gql(`
  mutation itemSeoUpdate($itemRef: ID!, $seoInput: SeoInput!) {
    itemSeoUpdate(itemRef: $itemRef, seoInput: $seoInput) {
      ...ItemFields
    }
  }
`);

export const POST_ITEM_CREATE = gql(`
  mutation itemCreate(
    $type: String!, 
    $method: String,
    $listings: [ItemListingInput]
    $descriptions: ItemInputDescriptions, 
    $itemCreateBookInput: ItemBookInput, 
    $itemCreateReleaseInput: ItemReleaseInput, 
    $itemCreateProductInput: ItemProductInput) {
      itemCreate(
        type: $type,
        method: $method,
        listings: $listings,
        descriptions: $descriptions, 
        itemCreateBookInput: $itemCreateBookInput, 
        itemCreateReleaseInput: $itemCreateReleaseInput,
        itemCreateProductInput: $itemCreateProductInput) {
          _id
          path
        }
    }
`);

export const POST_ITEM_LISTING_DELETE = gql(`
  mutation itemListingDelete($listingRef: ID!) {
    itemListingDelete(listingRef: $listingRef) {
      ...ItemFields
    }
  }
`);

export const POST_ITEM_DELETE = gql(`
  mutation itemDelete($itemRef: ID!) {
    itemDelete(itemRef: $itemRef)
  }
`);

export const POST_ITEM_LISTING_ADD = gql(`
  mutation itemListingAdd($itemRef: ID!, $itemListingInput: ItemListingInput!) {
    itemListingAdd(itemRef: $itemRef, itemListingInput: $itemListingInput) {
      ...ItemFields
    }
  }
`);

export const POST_ITEM_LISTING_UPDATE = gql(`
  mutation itemListingUpdate($listingRef: ID!, $itemListingInput: ItemListingInput!) {
    itemListingUpdate(listingRef: $listingRef, itemListingInput: $itemListingInput) {
      ...ItemFields
    }
  }
`);

export const POST_ITEM_LISTING_BULK_UPDATE = gql(`
  mutation itemListingBulkUpdate($listingRefs: [ID!], $fields: [ItemBulkUpdate!]) {
    itemListingBulkUpdate(listingRefs: $listingRefs, fields: $fields) {
      ...JobFields
    }
  }
`);

export const POST_ITEM_LISTING_DISCOGS = gql(`
  mutation itemListingDiscogs($listingRef: ID!, $list: Boolean, $unlist: Boolean) {
    itemListingDiscogs(listingRef: $listingRef, list: $list, unlist: $unlist) {
      ...ItemFields
    }
  }
`);

export const POST_ITEM_IMPORT_PREVIEW = gql(`
  mutation importPreview($rows: JSON!, $id: String!) {
    importPreview(rows: $rows, id: $id) {
      timeEstimate
      rows {
        itemId
        fields
        error
        newItem
        newListing
        listingId
      }
    }
  }
`);

export const POST_ITEM_IMPORT_PROCESS = gql(`
  mutation importProcess($file: Upload!, $id: String!) {
    importProcess(file: $file, id: $id) {
      ...JobFields
    }
  }
`);

export const POST_ITEM_STICKERS_GENERATE = gql(`
  mutation itemStickersGenerate($listingRefs: [ID]!, $templateRef: ID!, $fields: JSON) {
    itemStickersGenerate(listingRefs: $listingRefs, templateRef: $templateRef, fields: $fields) 
  }
`);

export const POST_ITEM_LINK_GENERATE = gql(`
  mutation itemGenerateLink($itemRef: ID!) {
    itemGenerateLink(itemRef: $itemRef)  {
      item {
        ...ItemFields
      }
      uri
    }
  }
`);

export const GET_INVENTORY_BULK_ESTIMATE = gql(`
  query inventoryBulkEstimate($listingRefs: [String!]!, $fields: JSON!) {
    inventoryBulkEstimate(listingRefs: $listingRefs, fields: $fields) {
      estimatedProcessTime
      durationString
    }
  }
`);

export const GET_INVENTORY_EXPORT_STICKERS = gql(`
  query inventoryExportStickers($listingRefs: [String!]!) {
    inventoryExportStickers(listingRefs: $listingRefs) {
      uri
    }
  }
`);

export const GET_ITEM_LABELS = gql(`
  query itemLabels($term: String) {
    itemLabels(term: $term) {
      labels {
        name
        id
      }
    }
  }
`);

export const POST_INVENTORY_EXPORT = gql(`
  mutation itemInventoryExport($type: String!, $filters: JSON!) {
    itemInventoryExport(type: $type, filters: $filters) {
      _id
    }
  }
`);

export const GET_ITEM_PRICE_SUGGESTIONS = gql(`
  query itemPriceSuggestions($id: Long, $discogsId: Long) {
    itemPriceSuggestions(id: $id, discogsId: $discogsId) {
      discogsScrappedPrices {
        priceString
        price
        mediaCondition
        sleeveCondition
        notes
        seller
        shipsFrom
      }
      discogsStatistics {
        have
        want
        lastSold
        lowest
        median
        highest
       }
      discogsUrl
    }
  }
`);

export const GET_ITEM_SALES_STATISTICS = gql(`
  query itemSalesStatistics($itemRef: ID!, $page: Int, $limit: Int) {
    itemSalesStatistics(itemRef: $itemRef, page: $page, limit: $limit) {
      pagination {
        page
        pages
        count
      }
      orders {
        ...OrderFields
      }
    }
  }
`);
