import { gql } from "../../__generated__/gql";

gql(`
  fragment PlanFields on Plan {
    _id
    seats
    active
    expires
    title
    recurrence
    description
    currency
    nextPaymentDate
    price {
      value
      currency
    }
    prices {
      value
      currency
    }
    trial {
      days
      available
      active
    }
    features {
      inventory {
        limit
      }
      campaigns {
        status
      }
      support {
        status
        level
      }
      stickers {
        status
      }
      salesChannels {
        pos {
          status
        }
        eshop {
          status
          customDomain {
            status
          }
        }
        discogs {
          status
        }
        meta {
          status
        }
        googleMerchant {
          status
        }
        bandcamp {
          status
        }
      }
    }
  }
`);

export const GET_PLANS = gql(`
  query plans($currencies: [String!]!) {
    plans(currencies: $currencies) {
      plans {
        ...PlanFields
      }
    }
  }
`);
