import { gql } from "../../__generated__/gql";

export const LIST_FIELDS = gql(`
  fragment ListFields on List {
    _id
    id
    title
    created
    description
    count
  }
`);

export const GET_LISTS = gql(`
  query lists {
    lists {
      lists {
        ...ListFields
      }
    }
  }
`);

export const GET_LIST = gql(`
  query list($listRef: ID!) {
    list(listRef: $listRef) {
      ...ListFields
    }
  }
`);

export const POST_LIST_ADD = gql(`
  mutation listAddBuyers($listRef: ID, $listTitle: String, $userRefs: [ID!]!) {
    listAddBuyers(listRef: $listRef, listTitle: $listTitle, userRefs: $userRefs) {
      ...ListFields
    }
  }
`);

export const POST_LIST_REMOVE = gql(`
  mutation listRemoveUsers($listRef: ID!, $userRefs: [ID!]!) {
    listRemoveUsers(listRef: $listRef, userRefs: $userRefs) {
      ...ListFields
    }
  }
`);
export const POST_LIST_CREATE = gql(`
  mutation listCreate($title: String!) {
    listCreate(title: $title) {
      ...ListFields
    }
  }
`);

export const POST_LIST_DELETE = gql(`
  mutation listDelete($listRef: ID!) {
    listDelete(listRef: $listRef)
  }
`);

export const POST_LIST_EDIT = gql(`
  mutation listEdit($listRef: ID!, $title: String!) {
    listEdit(listRef: $listRef, title: $title) {
        ...ListFields
    }
  }
`);
