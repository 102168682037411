import React, { useRef, useState } from "react";
import { MultiSelectValueType } from "./MultiSelect.types";
import {
  StyledButtonContainer,
  StyledContainer,
  StyledPopUpContainer,
  StyledPopUpListContainer,
  StyledSelectedLabel,
  StyledSeparator,
  StyledTitleContainer,
  StyledTitle
} from "./MultiSelect.styles";
import { AddIcon, ClearIcon } from "../SVGIcons";
import { Checkbox } from "../Checkbox";
import { Button } from "../Button";
import { useHandleClickOutside } from "../../hooks/useHandleClickOutside";
import { getOptionsLabel, truncateLabel } from "./MultiSelect.utils";
import { Typography } from "../Typography";
import { usePopupPosition } from "./MultiSelect.hooks";
import { GlobalStore } from "../../stores/global";
import { useTranslation } from "react-i18next";
import { SearchInput } from "../SearchInput";
import { createPortal } from "react-dom";

export type Props = {
  title: string;
  options: MultiSelectValueType[];
  selectedOptions: MultiSelectValueType[];
  handleChange: (options: MultiSelectValueType[]) => void;
  handleSearch?: () => void;
  handlePagination?: () => void;
  limit?: number;
  type?: "ovale" | "square";
  size?: "small" | "normal" | "large";
  // disable?
};

const Sizes = {
  small: {
    truncate: 12,
    popupWidth: 90
  },
  normal: {
    truncate: 18,
    popupWidth: 130
  },
  large: {
    truncate: 30,
    popupWidth: 200
  }
};

export const MultiSelect: React.FC<Props> = ({
  title,
  selectedOptions = [],
  options,
  handleChange,
  handleSearch,
  handlePagination,
  limit,
  type,
  size = "normal"
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState<MultiSelectValueType[]>(selectedOptions);
  const containerRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const popupListRef = useRef<HTMLDivElement>(null);
  const { isMobile } = GlobalStore.useState(c => c);
  const { t } = useTranslation();

  const hasDefaultOptions = options.length > 0;
  const hasSelectedOptions = selectedOptions?.length > 0;
  const optionsLabel = getOptionsLabel(selectedOptions);
  const popupPosition = usePopupPosition(containerRef, popupRef, isOpen);

  const handleClearSelection = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCheckedOptions([]);
    handleChange([]);
    setIsOpen(false);
  };

  const sizeToApply: { truncate: number; popupWidth: number } = Sizes[size];

  const handleCheckboxClick = (option: MultiSelectValueType) => {
    const isOptionChecked = checkedOptions.some(checkedOption => checkedOption.value === option.value);
    if (!isOptionChecked) {
      return setCheckedOptions(state => [...state, option]);
    }
    setCheckedOptions(state => state?.filter(stateOption => stateOption.value !== option.value));
  };

  const handleSubmit = () => {
    setIsOpen(false);
    handleChange(checkedOptions);
  };

  const handleClear = () => {
    setCheckedOptions([]);
  };

  const handleScroll = () => {
    if (!hasDefaultOptions || !handlePagination) return;
    if (popupListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = popupListRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        handlePagination();
      }
    }
  };

  useHandleClickOutside(containerRef, () => {
    setIsOpen(false);
    setCheckedOptions(selectedOptions);
  });

  return (
    <StyledContainer ref={containerRef}>
      <StyledTitleContainer
        active={isOpen}
        isSquared={type === "square"}
        hasSelectedOptions={hasSelectedOptions}
        data-testid="tag-container"
        onClick={() => {
          setIsOpen(!isOpen);
        }}>
        {!hasSelectedOptions && <AddIcon datatestid="add-icon" />}
        {hasSelectedOptions && <ClearIcon datatestid="clear-icon" onClick={handleClearSelection} />}
        <StyledTitle>
          {title}
          {hasSelectedOptions && (
            <>
              <StyledSeparator>|</StyledSeparator>
              {isMobile ? (
                <StyledSelectedLabel>{`${optionsLabel.length} filters`}</StyledSelectedLabel>
              ) : (
                optionsLabel.map((option, i) => {
                  const lastItem = i === optionsLabel?.length - 1;
                  const truncatedLabel = truncateLabel(option, sizeToApply.truncate);
                  const title = truncatedLabel === option ? "" : option;
                  const optionCopy = `${truncatedLabel}${lastItem ? "" : ", "}`;
                  return (
                    <StyledSelectedLabel title={title} key={`${option}-${i}`}>
                      {optionCopy}
                    </StyledSelectedLabel>
                  );
                })
              )}
            </>
          )}
        </StyledTitle>
      </StyledTitleContainer>
      {createPortal(
        <StyledPopUpContainer
          width={sizeToApply.popupWidth}
          data-testid="multiselect-popup"
          ref={popupRef}
          style={{ display: isOpen ? "block" : "none", ...popupPosition }}>
          {handleSearch && <SearchInput handleChange={handleSearch} styleProps={{ marginBottom: "5px" }} />}
          <StyledPopUpListContainer ref={popupListRef} onScroll={handleScroll}>
            {hasDefaultOptions ? (
              options?.map((option, i) => {
                const checked = checkedOptions.some(checkedOption => checkedOption.value === option.value);
                const disabled = !checked && checkedOptions.length === limit;
                const truncatedLabel = truncateLabel(option.label, sizeToApply.truncate);
                const title = truncatedLabel === option.label ? "" : option.label;
                const marginTop = i !== 0 ? "5px" : "0";
                return (
                  <Checkbox
                    key={option.label + `-${i}`}
                    label={truncatedLabel}
                    onClick={() => handleCheckboxClick(option)}
                    checked={checked}
                    containerStyleProps={{ marginTop }}
                    disabled={disabled}
                    datatestid={`checkbox-${option.label}`}
                    title={title}
                  />
                );
              })
            ) : (
              <Typography variant="copy" tag="span" style={{ whiteSpace: "nowrap" }}>
                {t("No options available")}
              </Typography>
            )}
          </StyledPopUpListContainer>
          {hasDefaultOptions && (
            <StyledButtonContainer>
              <Button fullWidth variant="secondary" onClick={handleClear} disabled={checkedOptions.length === 0}>
                {t("Clear")}
              </Button>
              <Button
                fullWidth
                variant="primary"
                datatestid="button-submit"
                onClick={handleSubmit}
                disabled={JSON.stringify(selectedOptions) === JSON.stringify(checkedOptions)}>
                {t("Submit")}
              </Button>
            </StyledButtonContainer>
          )}
        </StyledPopUpContainer>,
        document.getElementById("portal") ?? document.body
      )}
    </StyledContainer>
  );
};
