import { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { submitGoogleOauthCode, submitGoogleAnalyticsView } from "../@services/configService";
import Loader from "../components/common/loader";
import { GlobalStore } from "../stores/global";
import URI from "urijs";
import { useTranslation } from "react-i18next";
import { Button } from "../componentsV2/Button";

export default function GoogleOauth() {
  const { configReload } = GlobalStore.useState(c => c);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  const [error, setError] = useState<any>(undefined);
  const [accounts, setAccounts] = useState<any>(undefined);
  const currentUri = new URI(window.location.href);
  const search = currentUri.search(true);

  const { t } = useTranslation();

  const handleSelectProperty = async (property: any, stream: any) => {
    try {
      submitGoogleAnalyticsView({
        property: {
          id: property.name,
          name: property.displayName,
          tracker: stream.webStreamData.measurementId
        }
      })
        .then(() => {
          setRedirect("/");
          configReload();
        })
        .catch(err => setError(err));
    } catch (error: any) {}
  };

  useEffect(() => {
    submitGoogleOauthCode(search)
      .then(({ data }) => {
        setAccounts([...data.accounts]);
      })
      .catch(err => setError(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <Loader withMargins />;
  else if (redirect) return <Redirect to={redirect} />;

  return (
    <div id="googleOauth">
      <div className="header">
        <h1>{t("Authentication for Google Analytics 4")}</h1>
      </div>
      <div className="content">
        {error ? (
          <div className="error">
            <h2 className="danger">{error.data}</h2>
            <Link to="/editor/settings">{t("Start setup again")}</Link>
          </div>
        ) : null}
        {accounts ? (
          <>
            <p>
              <strong>{t("Select the Analytics property for the dashboard widget")}:</strong>
            </p>
            <ul className="views">
              {accounts && accounts.length ? (
                accounts.map((a: any) =>
                  a.properties.map((p: any) =>
                    p.streams
                      .filter((s: any) => s.type === "WEB_DATA_STREAM")
                      .map((s: any) => (
                        <li key={p.id}>
                          <Button variant="primary" type="button" onClick={() => handleSelectProperty(p, s)}>
                            {p.displayName} / {s.displayName}
                          </Button>
                        </li>
                      ))
                  )
                )
              ) : (
                <p>{t("No GA properties were found. Please add a property and a web data stream to your Analytics acccount")}.</p>
              )}
            </ul>
          </>
        ) : (
          <p>{t("No properties or accounts were found")}...</p>
        )}
      </div>
    </div>
  );
}
