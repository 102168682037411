import styled from "styled-components";
import { colorsAsRgbArray, colorsAsRgbString, applyOpacity } from "@common-ground-io/colors";
import { getThemeProps } from "../../utils/styles";
import { BaseStyledComponentProps, LayoutType } from "../../types/styles.types";

type ThemeProps = {
  fontColorLabel: string;
  fontColorHover: string;
  disabledFontColorLabel: string;
  fontColorChecked: string;
  hoverBgColorInput: string;
  bgColorInput: string;
  iconColor: string;
  iconColorActive: string;
  iconColorActiveDisabled: string;
  borderColor: string;
};

type IconProps = {
  checked: boolean;
  disabled: boolean;
};

export type CheckboxContainerProps = {
  marginTop?: string;
  disabled?: boolean;
};

type ThemeVariants = {
  primary: string;
};

type CheckboxProps = BaseStyledComponentProps<ThemeProps, ThemeVariants> & {
  disabled?: boolean;
};

const baseVariant = {
  hoverBgColorInput: applyOpacity(colorsAsRgbArray.greyDark, 0.4),
  iconColor: "transparent"
};

const primaryLightVariant = {
  fontColorLabel: colorsAsRgbString.grey,
  fontColorHover: colorsAsRgbString.greyDark,
  disabledFontColorLabel: colorsAsRgbString.greyLight,
  bgColorInput: colorsAsRgbString.greyLight,
  fontColorChecked: colorsAsRgbString.greyDark,
  borderColor: colorsAsRgbString.greyLight,
  iconColorActive: colorsAsRgbString.greyDarker,
  iconColorActiveDisabled: colorsAsRgbString.grey,
  ...baseVariant
};

const primaryDarkVariant = {
  // font
  fontColorLabel: colorsAsRgbString.grey,
  fontColorHover: colorsAsRgbString.greyLight,
  disabledFontColorLabel: colorsAsRgbString.greyDark,
  bgColorInput: colorsAsRgbString.greyDarker,
  fontColorChecked: colorsAsRgbString.greyDark,
  borderColor: colorsAsRgbString.greyDark,
  iconColorActive: colorsAsRgbString.greyLightest,
  iconColorActiveDisabled: colorsAsRgbString.greyDark,
  ...baseVariant
};

const layout: LayoutType<ThemeProps, ThemeVariants> = {
  light: {
    primary: primaryLightVariant
  },
  dark: {
    primary: primaryDarkVariant
  }
};

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
`;

export const StyledCheckbox = styled.div<CheckboxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: ${props => getThemeProps(layout, props).bgColorInput};
  border-radius: 5px;
  transition: all 150ms;
  border: 1px solid ${props => getThemeProps(layout, props).borderColor};
`;

export const Icon = styled.svg<IconProps>`
  height: 10px;
  width: 12px;
  stroke-width: ${({ checked }) => checked && 1};
  stroke: ${props =>
    props.disabled
      ? getThemeProps(layout, props).iconColorActiveDisabled
      : props.checked
      ? getThemeProps(layout, props).iconColorActive
      : getThemeProps(layout, props).iconColor};
  fill: ${props =>
    props.disabled
      ? getThemeProps(layout, props).iconColorActiveDisabled
      : props.checked
      ? getThemeProps(layout, props).iconColorActive
      : getThemeProps(layout, props).iconColor};
`;

export const Styledlabel = styled.label<CheckboxProps>`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  cursor: inherit;
  margin-left: 5px;
  color: ${props => (props.disabled ? getThemeProps(layout, props).disabledFontColorLabel : getThemeProps(layout, props).fontColorLabel)};
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Container = styled.div<CheckboxContainerProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop || "0"};
  cursor: pointer;
  &:hover ${StyledCheckbox} {
    background: ${props => (props.disabled ? getThemeProps(layout, props).bgColorInput : getThemeProps(layout, props).hoverBgColorInput)};
  }
  &:hover ${Styledlabel} {
    color: ${props => (props.disabled ? getThemeProps(layout, props).disabledFontColorLabel : getThemeProps(layout, props).fontColorHover)};
  }
`;
