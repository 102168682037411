import styled from "styled-components";
import { devices } from "../../utils/styles";
import { applyOpacity, colorsAsRgbArray } from "@common-ground-io/colors";

export const SectionHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  @media ${devices.tabletL} {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 10px;
  @media ${devices.tabletL} {
    margin-left: 0px;
  }
`;

export const LeftSectionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

export const RightSectionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

export const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
`;

export const FeatureGatingContainer = styled.div<{ isBelowIpad?: boolean }>`
  display: grid;
  padding: var(--gutter);
  border-radius: 10px;
  grid-gap: "300px";
  align-items: flex-end;
  grid-template-columns: ${({ isBelowIpad }) => (isBelowIpad ? "1fr" : "4fr auto")};
  background-color: ${applyOpacity(colorsAsRgbArray.alert, 0.2)};
  /* margin-top: calc(var(--gutter) * 2); */
  margin-bottom: calc(var(--gutter) * 2);
  @media ${devices.tabletL} {
    grid-gap: 50px;
  }
`;
