import { useState, useEffect } from "react";
import Cookies from "js-cookie";

export const useTheme = (window: any) => {
  const themeFromCookies = Cookies.get("theme");
  const [theme, setTheme] = useState<string | undefined>(undefined);

  const handleChange = (value: any) => setTheme(value);

  const eventListener = (e: any) => handleChange(e.matches ? "dark" : "light");

  useEffect(() => {
    if (themeFromCookies) setTheme(themeFromCookies);
    else {
      try {
        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", eventListener);

        handleChange(window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");

        return () => {
          window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", eventListener);
        };
      } catch (error: any) {
        handleChange("light");
      }
    }
  }, [themeFromCookies]);

  return { theme, setTheme };
};
