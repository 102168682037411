import React, { useState, useRef } from "react";
import Input from "../styled/input";
import Tile from "../common/tile";
import Liner from "../common/liner";
import { Link, useHistory } from "react-router-dom";
import Loader from "../common/loader";
import { GlobalStore } from "../../stores/global";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PLAYLISTS, POST_PLAYLIST_CREATE } from "../../graphql/queries/playlist";
import Modal, { PropsToForward } from "../../components/modal";
import { Config, Playlist as IPlaylist } from "../../__generated__/graphql";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import { Typography } from "../../componentsV2/Typography";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";

export default function Playlists() {
  const { config, addNotification } = GlobalStore.useState(s => s);
  const [createPlaylist] = useMutation(POST_PLAYLIST_CREATE);
  const [isCreating, setIsCreating] = useState(false);
  const { data } = useQuery(GET_PLAYLISTS, { fetchPolicy: "cache-and-network" });
  const modalRef: any = useRef<PropsToForward>();
  const history = useHistory();
  const [playlistTitle, setPlaylistTitle] = useState("");
  const { t } = useTranslation();
  document.title = "Playlists";
  const playlists = data?.playlists?.playlists;
  const handleCreatePlaylist = async (e: any) => {
    setIsCreating(true);
    e.preventDefault();
    createPlaylist({ variables: { title: e.target.title.value } })
      .then(({ data }) => {
        if (data?.playlistCreate) history.push(`/playlist/${data.playlistCreate.id}/edit`);
      })
      .catch(e => addNotification({ ok: 0, message: e.message }))
      .finally(() => setIsCreating(false));
  };

  if (!playlists) return <Loader withMargins />;

  return (
    <div id="playlists">
      <Modal style={{}} ref={modalRef}>
        <div id="newThemeModal">
          <form onSubmit={handleCreatePlaylist}>
            <ModalHeaderContainer>
              <Typography variant="pageTitle" tag="h2">
                {t("Create a playlist")}
              </Typography>
              <button className="reset" type="button" onClick={() => modalRef.current.close()}>
                <i className="cg-icon-burger-close" />
              </button>
            </ModalHeaderContainer>
            <div className="content">
              <Input
                disabled={isCreating}
                autoFocus={true}
                value={playlistTitle}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPlaylistTitle(e.target.value)}
                label="Enter a title for this new playlist"
                type="text"
                name="title"
                placeholder="Enter a title"
                autoComplete="off"
                required
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "var(--gutter)" }}>
              <span />
              <ButtonV2 variant="primary" disabled={isCreating || !playlistTitle} type="submit">
                {isCreating ? <Loader /> : "Create Playlist"}
              </ButtonV2>
            </div>
          </form>
        </div>
      </Modal>
      <section className="header">
        <Typography variant="pageTitle" tag="h1">
          {t("Playlists")}
        </Typography>
        <ButtonV2 type="button" variant="primary" onClick={() => modalRef.current?.open()}>
          {t("Create a playlist")}
        </ButtonV2>
      </section>
      {playlists.length ? (
        playlists.map((p, i) => (
          <Liner key={p.id} index={i}>
            <Playlist config={config as Config} playlist={p} t={t} />
          </Liner>
        ))
      ) : (
        <p>{t("No playlist were found")}</p>
      )}
    </div>
  );
}

const Playlist = ({ playlist, config, t }: { playlist: IPlaylist; config: Config; t: TFunction }) => {
  return (
    <div className="playlist">
      <div className="header">
        <h2>
          {playlist.title} ({playlist.entries.length})
        </h2>
        <div className="right">
          <Link to={`/playlist/${playlist.id}/edit`}>
            <ButtonV2 variant="primary">{t("Edit")}</ButtonV2>
          </Link>
        </div>
      </div>
      <div className="entries">
        {playlist.entries.map((e, i) => (
          <Tile config={config} key={i} entry={e.item} />
        ))}
      </div>
    </div>
  );
};

export { Playlist };
