import { useState, useEffect, useCallback } from "react";
import { Button } from "../../componentsV2/Button";
import Loader from "../common/loader";
import Checkout from "./includes/checkout";
import { GlobalStore } from "../../stores/global";
import { GET_CHECKOUTS, POST_CHECKOUT_DELETE, POST_CHECKOUT_CREATE } from "../../graphql/queries/checkout";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { GET_LISTS } from "../../graphql/queries/list";
import * as amplitude from "@amplitude/analytics-browser";
import { Checkout as ICheckout } from "../../__generated__/graphql";
import { Typography } from "../../componentsV2/Typography";
import { useTranslation } from "react-i18next";
import { FeatureGatingHeader } from "../../componentsV2/SectionHeader/SectionHeader";

export default function OrderNew() {
  const { addNotification, session, config } = GlobalStore.useState(c => c);
  const [submittedCheckout, setSubmittedCheckout] = useState<ICheckout | null>();
  const { t } = useTranslation();
  const [getCheckouts, { loading, data, refetch }] = useLazyQuery(GET_CHECKOUTS, { fetchPolicy: "cache-and-network" });
  const [deleteCheckout] = useMutation(POST_CHECKOUT_DELETE);
  const [createCheckout] = useMutation(POST_CHECKOUT_CREATE);
  const { data: listData } = useQuery(GET_LISTS);
  const lists = listData?.lists.lists;

  const loadCheckouts = async () => {
    await getCheckouts({ variables: { status: "open", origin: "Shop" } });
  };

  const checkouts = data && data.checkouts.checkouts;

  const handleCreateCheckout = useCallback(async () => {
    if (checkouts && checkouts.length > 10) return addNotification({ ok: 0, message: "You may not have more than 10 open checkouts" });
    try {
      setSubmittedCheckout(null);
      await createCheckout();
      amplitude.track("Checkout created");
      refetch();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.message });
    }
  }, [addNotification, checkouts, session?.user]);

  useEffect(() => {
    const getData = async () => {
      try {
        loadCheckouts();
      } catch (e: any) {
        addNotification({ ok: 0, message: e.message });
      }
    };
    getData();
  }, []);

  const handleDeleteCheckout = async (id: string) => {
    try {
      const results = await deleteCheckout({ variables: { id } });
      refetch();
      amplitude.track("Checkout deleted");
      if (results.data) addNotification({ ok: 1, message: results.data.checkoutDelete });
    } catch (error: any) {
      addNotification({ ok: 0, message: error.toString() });
    }
  };

  const handleSubmitted = async (checkout: ICheckout) => {
    setSubmittedCheckout(checkout);
    refetch();
  };

  if (loading || !checkouts || !lists) return <Loader withMargins />;
  return (
    <div id="checkoutPage">
      <section className="header">
        <Typography variant="pageTitle" tag="h1">
          {t("Checkouts")} ({checkouts.length})
        </Typography>
        <div style={{ display: "flex", gap: "var(--gutter", alignItems: "center" }}>
          <Button variant="secondary" type="link" href="/checkouts/open">
            {t("Eshop open checkouts")}
          </Button>
          {checkouts.length ? (
            <Button variant="primary" onClick={handleCreateCheckout}>
              {t("Create a checkout")}
            </Button>
          ) : null}
        </div>
      </section>
      {!config?.plan.features.salesChannels.pos.status ? <FeatureGatingHeader /> : null}
      <section id="checkouts">
        {submittedCheckout ? (
          <Checkout
            onDelete={null}
            lists={lists}
            onSubmitted={null}
            checkoutRef={submittedCheckout._id}
            createNewCheckout={handleCreateCheckout}
          />
        ) : null}
        {checkouts && checkouts.length ? (
          <>
            {checkouts.map(c => (
              <Checkout
                onDelete={handleDeleteCheckout}
                checkoutRef={c._id}
                onSubmitted={handleSubmitted}
                createNewCheckout={handleCreateCheckout}
                key={c._id}
                lists={lists}
              />
            ))}
          </>
        ) : null}
        {(!checkouts || !checkouts.length) && !submittedCheckout ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h2>{t("Get started by creating a checkout")}</h2>
            <div>
              <Button variant="primary" onClick={handleCreateCheckout}>
                {t("Create a checkout")}
              </Button>
            </div>
          </div>
        ) : null}
      </section>
    </div>
  );
}
