import React from "react";
import { StyledLink, StyledTypography } from "./Typography.styles";
import { Link } from "react-router-dom";

export type TypographyLevel = "info" | "warning" | "success" | "highlight" | "secondary" | "normal";
export type TypographyVariant = "copy" | "subTitle" | "secondaryTitle" | "pageTitle";

type Props = {
  children: React.ReactNode;
  tag?: "h1" | "h2" | "h3" | "p" | "span";
  level?: TypographyLevel;
  variant: TypographyVariant;
  style?: React.CSSProperties;
};

export const Typography: React.FC<Props> = ({ children, tag = "span", level = "normal", variant, style }) => {
  return (
    <StyledTypography as={tag} level={level} variant={variant} style={style}>
      {children}
    </StyledTypography>
  );
};

type LinkProps = {
  children: React.ReactNode;
  to: string;
  external?: boolean;
  level?: TypographyLevel;
  style?: React.CSSProperties;
};

export const HLink: React.FC<LinkProps> = ({ children, external = false, to, level = "normal", style }) => {
  return (
    <StyledLink level={level} style={style}>
      {external ? (
        <a target="_blank" href={to} rel="noreferrer">
          {children}
        </a>
      ) : (
        <Link to={to}>{children}</Link>
      )}
    </StyledLink>
  );
};
