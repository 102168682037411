import React from "react";
import { CheckboxContainer, CheckboxContainerProps, Container, HiddenCheckbox, Icon, StyledCheckbox, Styledlabel } from "./Checkbox.styles";
import { LayoutVariantKey } from "../../types/styles.types";

type Props = {
  checked: boolean;
  disabled?: boolean;
  label: string;
  onClick: () => void;
  variant?: LayoutVariantKey;
  containerStyleProps?: CheckboxContainerProps;
  datatestid?: string;
  title?: string;
};

export const Checkbox: React.FC<Props> = ({
  checked = false,
  disabled = false,
  label,
  onClick,
  variant,
  containerStyleProps,
  datatestid,
  title
}) => {
  const handleClick = () => {
    if (!disabled) onClick();
  };
  return (
    <Container {...containerStyleProps} data-testid={datatestid} onClick={handleClick} disabled={disabled}>
      <CheckboxContainer>
        <HiddenCheckbox disabled={disabled} />
        <StyledCheckbox variant={variant} disabled={disabled}>
          <Icon viewBox="0 0 9 8" checked={checked} disabled={disabled}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.37448 1C7.21504 1 7.05662 1.06804 6.93453 1.206L3.31282 5.29844L2.06345 3.88669C1.81928 3.61077 1.42772 3.61077 1.18355 3.88669C0.938818 4.1626 0.938818 4.60779 1.18355 4.8837L2.87287 6.7926C3.11705 7.06913 3.5086 7.06913 3.75277 6.7926L7.81687 2.20301C8.06104 1.9271 8.06104 1.48191 7.81687 1.206C7.6945 1.06804 7.53393 1 7.37448 1Z"
            />
          </Icon>
        </StyledCheckbox>
      </CheckboxContainer>
      <Styledlabel title={title} variant={variant} disabled={disabled}>
        {label}
      </Styledlabel>
    </Container>
  );
};
