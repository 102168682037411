import { gql } from "../../__generated__/gql";

gql(`
  fragment CollectionFields on Collection {
    _id
    id
    handle
    title
    created
    viewType
    description {
      content
    }
    seo {
      ...SeoFields
    }
    banner {
      media {
        _id
        id
        url
        formatArray {
          name
          url
        }
      }
      format
    }
    sort {
      key
      order
    }
    segments {
      _id
      title
      filters {
        _id
        id
        title
        value
        valueWithLabels
      }
    }
  }
`);

export const GET_COLLECTIONS = gql(`
  query collectionsConnection($page: Int, $perPage: Int, $term: String) {
    collectionsConnection(page: $page, perPage: $perPage, term: $term) {
      collections {
        ...CollectionFields
      }
      pagination {
        pages
        page
        count
      }
    }
  }
`);

export const GET_COLLECTION = gql(`
  query collection($id: Int!) {
    collection(id: $id) {
      ...CollectionFields
    }
  }
`);

export const GET_COLLECTION_DATA = gql(`
  query collectionData($segment: JSON!, $page: Int, $limit: Int, $sort: String, $order: Int, $randomise: Boolean) {
    collectionData(segment: $segment, page: $page, limit: $limit, sort: $sort, order: $order, randomise: $randomise) {
      pagination {
        pages
        page
        count
        limit
      }
      items {
        ...ItemFields
      }
    }
  }
`);

export const POST_COLLECTION_CREATE = gql(`
  mutation collectionCreate($title: String!, $collectionRef: ID) {
    collectionCreate(title: $title, collectionRef: $collectionRef) {
      ...CollectionFields
    }
  }
`);

export const POST_COLLECTION_UPDATE = gql(`
  mutation collectionUpdate($ref: ID!, $title: String!, $viewType: String, $sort: CollectionSortInput, $bannerFormat: String, $segments: JSON!, $bannerRef: ID, $description: String) {
    collectionUpdate(title: $title, bannerRef: $bannerRef, sort: $sort, viewType: $viewType, bannerFormat: $bannerFormat, description: $description, ref: $ref, segments: $segments) {
      ...CollectionFields
    }
  }
`);

export const POST_COLLECTION_DELETE = gql(`
  mutation collectionDelete($collectionRef: ID!) {
    collectionDelete(collectionRef: $collectionRef)
  }
`);

export const POST_COLLECTION_SEO_UPDATE = gql(`
  mutation collectionSeoUpdate($collectionRef: ID!, $seoInput: SeoInput!) {
    collectionSeoUpdate(collectionRef: $collectionRef, seoInput: $seoInput) {
      ...CollectionFields
    }
  }
`);
