import { useRef, useState } from "react";
import Modal from "../modal";
import Browser from "./browser";
import { GlobalStore } from "../../stores/global";
import Uploader from "./uploader";
import { FaTrash } from "react-icons/fa";
import { Media } from "../../__generated__/graphql";
import { useTranslation } from "react-i18next";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";
import { Typography } from "../../componentsV2/Typography";
import { Button } from "../../componentsV2/Button";

const MediaSelector = ({
  media,
  onSelected,
  onClear,
  extensions = [],
  placeholder,
  formatForPreview
}: {
  media?: Media | null;
  onSelected: any;
  onClear: any;
  extensions: string[];
  placeholder?: string;
  formatForPreview?: string;
}) => {
  const addNotification = GlobalStore.useState(c => c.addNotification);
  const browserRef = useRef<any>();
  const modalRef = useRef<any>(null);
  const [isUploading, setIsUploading] = useState(false);

  const { t } = useTranslation();

  const handleSelectMedia = async (media: Media) => {
    if (!media) return;
    await onSelected([media]);
    if (modalRef.current) modalRef.current.close();
  };

  const handleRemoveThumbnail = async () => {
    if (onClear) await onClear();
  };

  const handleMediaUpload = async () => {
    setIsUploading(false);
  };

  let mediaForPreview = formatForPreview && media?.formatArray?.find((f: any) => f.name === formatForPreview);
  mediaForPreview = !mediaForPreview ? media?.formatArray?.find((f: any) => f.name === "thumbnail") : undefined;

  return (
    <div className="mediaSelector">
      <Modal ref={modalRef} className="mediaModal" style={{}} onClose={null}>
        <div className="mediaSelectorModal">
          <div style={{ padding: "var(--gutter)", paddingBottom: "0px" }}>
            <ModalHeaderContainer>
              <div style={{ display: "flex" }}>
                <Typography variant="pageTitle" tag="h2">
                  {isUploading ? t("Upload a media") : t("Select a media")}
                </Typography>
                <Button
                  styleProps={{ marginLeft: "var(--gutter)" }}
                  variant="secondary"
                  type="button"
                  onClick={() => setIsUploading(!isUploading)}>
                  {isUploading ? t("Back to browser") : t("Upload a media")}
                </Button>
              </div>
              <button className="reset" type="button" onClick={() => modalRef.current.close()}>
                <i className="cg-icon-burger-close" />
              </button>
            </ModalHeaderContainer>
          </div>

          <div className="content">
            {isUploading ? (
              <Uploader addNotification={addNotification} onUploaded={handleMediaUpload} />
            ) : (
              <Browser
                extensions={extensions}
                addNotification={addNotification}
                handleSelectSingleMedia={handleSelectMedia}
                ref={browserRef}
                multiple={false}
                clickToSelect={true}
              />
            )}
          </div>
        </div>
      </Modal>
      <div className="content">
        {media ? (
          <div className="image">
            <div className="toolbox">
              <div className="tool" onClick={() => handleRemoveThumbnail()}>
                <FaTrash />
              </div>
            </div>
            <img alt="Thumbnail" src={(mediaForPreview || media).url || ""} />
          </div>
        ) : (
          <div onClick={() => modalRef.current.open()} className="placeholder">
            <p>{placeholder || t("Click to select or upload media")}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaSelector;
