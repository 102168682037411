import React from "react";
import { Typography } from "../Typography";
import {
  ButtonsContainer,
  FeatureGatingContainer,
  LeftSectionContainer,
  RightSectionContainer,
  SectionHeaderContainer
} from "./SectionHeader.styles";
import { Button } from "../Button";
import { useTranslation } from "react-i18next";

type LeftSectionProps = {
  children: React.ReactNode;
};

type RightSectionProps = {
  children: React.ReactNode;
};

type Props = {
  title: string;
  children: React.ReactNode;
};

export const SectionHeader: React.FC<Props> = ({ title, children }) => {
  return (
    <SectionHeaderContainer>
      <Typography variant="pageTitle" tag="h1" level="normal" style={{ whiteSpace: "nowrap" }}>
        {title}
      </Typography>
      <ButtonsContainer>{children}</ButtonsContainer>
    </SectionHeaderContainer>
  );
};

export const LeftSection: React.FC<LeftSectionProps> = ({ children }) => {
  return <LeftSectionContainer>{children}</LeftSectionContainer>;
};

export const RightSection: React.FC<RightSectionProps> = ({ children }) => {
  return <RightSectionContainer>{children}</RightSectionContainer>;
};

export const FeatureGatingHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FeatureGatingContainer>
      <div>
        <Typography variant="subTitle" level="warning" tag="h2" style={{ marginBottom: "var(--gutter)" }}>
          {t("Feature not available")}
        </Typography>
        <Typography variant="copy" level="warning">
          {t("This feature is not available for your account")}.
          <br />
          {t("Please consider upgrading to a higher tier to access it")}.
        </Typography>
      </div>
      <div>
        <Button href={"/preferences/billing"} variant="warning" type="link">
          {t("Upgrade")}
        </Button>
      </div>
    </FeatureGatingContainer>
  );
};
