import React from "react";
import { Button } from "../../../../componentsV2/Button";
import Zone from "../../../styled/zone";
import { POST_CONFIG_DELETE_ACCOUNT } from "../../../../graphql/queries/config";
import { useMutation } from "@apollo/client";
import { Config } from "../../../../__generated__/graphql";
import { AddNotification } from "../../../../types/globals";
import { getConfigProperty } from "../../../../utils";
import { useTranslation } from "react-i18next";

const Termination = ({
  config,
  addNotification,
  logout,
  helpPanel
}: {
  config: Config;
  addNotification: AddNotification;
  logout: any;
  helpPanel: any;
}) => {
  const [deleteAccount] = useMutation(POST_CONFIG_DELETE_ACCOUNT);
  const { t } = useTranslation();

  const handleDeleteAccount = async (form: any) => {
    form.preventDefault();
    if (window.confirm("You are about to terminate your account, are you sure?")) {
      try {
        await deleteAccount();
        logout();
      } catch (error: any) {
        addNotification({ ok: 0, message: error.message });
      }
    }
  };

  return (
    <Zone>
      <div className="header">
        <h2>{t("Terminate my account")}</h2>
        <Button variant="secondary" type="button" onClick={() => helpPanel.load("7329023")}>
          <span>{t("Help?")}</span>
        </Button>
      </div>
      <div className="content">
        <form className="deleteAccount" onSubmit={handleDeleteAccount}>
          <div className="left">
            <p>
              {t("A dump of your data will be sent to {{config}} as a zip file.", {
                config: getConfigProperty(config, "information", "email") as string
              })}
              <br />
              {t("Once an account deleted, it is not possible to retrieve its data or to restore it.")}
              <br />
              {t("During this process you will be logged out.")}
            </p>
          </div>
          <div className="right">
            <Button variant="warning" type="submit">
              {t("Terminate my account")}
            </Button>
          </div>
        </form>
      </div>
    </Zone>
  );
};

export default Termination;
