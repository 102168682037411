import React, { useEffect, useState, useRef } from "react";
import { Link, Prompt, useHistory } from "react-router-dom";
import Button from "../styled/button";
import Checkbox from "../styled/checkbox";
import Input from "../styled/input";
import Zone from "../styled/zone";
import Loader from "../common/loader";
import clone from "clone";
import useEvent from "../common/hooks/useEvent";
import Four0Four from "../global/404";
import { SelectCreatable } from "../styled/select";
import shortid from "shortid";
import MediaSelector from "../media/selector";
import BlockElements from "./blocks";
import { GlobalStore } from "../../stores/global";
import { GET_COLLECTIONS } from "../../graphql/queries/collection";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ARTICLE, POST_ARTICLE_DELETE, POST_ARTICLE_UPDATE } from "../../graphql/queries/article";
import { GET_LISTS } from "../../graphql/queries/list";
import { GET_PLAYLISTS } from "../../graphql/queries/playlist";
import DatePicker from "../styled/datePicker";
import { Button as ButtonV2 } from "../../componentsV2/Button";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import SeoEditor from "../../componentsV2/SEO";
import { Typography } from "../../componentsV2/Typography";
import ModalComponent from "../modal";
import { ModalHeaderContainer } from "../../componentsV2/SectionHeader/SectionHeader.styles";
import { Article } from "../../__generated__/graphql";
import moment from "moment";
import styled from "styled-components";

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--gutter);
  margin-bottom: var(--gutter);
`;

export const sizes = [
  {
    name: "thumbnail",
    width: 300,
    height: 300,
    options: {
      fit: "cover",
      position: "center"
    }
  },
  {
    name: "square",
    width: 600,
    height: 600,
    options: {
      fit: "cover",
      position: "center"
    }
  },
  {
    name: "banner",
    width: 1920,
    height: 800,
    options: {
      fit: "cover",
      position: "center"
    }
  },
  {
    name: "banner-free-ratio",
    width: 1920
  },
  {
    name: "banner-sm",
    width: 1440,
    height: 600,
    options: {
      fit: "cover",
      position: "center"
    }
  },
  {
    name: "large",
    width: 1440
  },
  {
    name: "medium",
    width: 1024
  },
  {
    name: "small",
    width: 720
  }
];

const rowTypes = (t: TFunction) => [
  { label: t("Feed of articles by tag"), value: "feed" },
  { label: t("Columns of content blocks"), value: "columns" }
];

const sorts = (t: TFunction) => [
  { label: t("Published - latest first"), value: { key: "published", order: -1 } },
  { label: t("Published - oldest first"), value: { key: "published", order: 1 } },
  { label: t("Title - A-Z"), value: { key: "title", order: 1 } },
  { label: t("Title - Z-A"), value: { key: "title", order: -1 } }
];

export default function ArticleEditor(props: any) {
  const { config, addNotification, isBelowIpad } = GlobalStore.useState((c: any) => c);
  const [article, setArticle] = useState<Article | undefined>();
  const [dirty, setDirty] = useState(false);
  const history = useHistory();
  const [focusedRow, setFocusedRow] = useState<any>();
  const [stringRef, setStringRef] = useState<string>("");
  const id = parseInt(props.match.params.id);
  const { data, loading } = useQuery(GET_ARTICLE, { fetchPolicy: "cache-and-network", variables: { id } });
  const { data: listData } = useQuery(GET_LISTS);
  const [updateArticle, { loading: updating }] = useMutation(POST_ARTICLE_UPDATE);
  const [deleteArticle, { loading: deleting }] = useMutation(POST_ARTICLE_DELETE);
  const { data: playlistsData } = useQuery(GET_PLAYLISTS);
  const uri = process.env.NODE_ENV === "development" ? "http://localhost:3002" : config.uri;
  const { t } = useTranslation();
  const modalRef = useRef<any>();

  const { data: collectionsData, refetch } = useQuery(GET_COLLECTIONS, {
    fetchPolicy: "cache-and-network",
    variables: { page: 1, perPage: 50 }
  });
  const tags = data?.articleTags || [];

  const collections = collectionsData?.collectionsConnection?.collections;
  const lists = listData?.lists.lists;
  const playlists = playlistsData?.playlists?.playlists;

  useEffect(() => {
    if (data?.article) {
      setArticle(clone(data.article));
      setStringRef(JSON.stringify(clone(data.article)));
    }
  }, [data]);

  useEffect(() => {
    if (article && stringRef && JSON.stringify(article) !== stringRef) {
      setDirty(true);
    }
  }, [stringRef, article]);

  const handleKeyPress = (e: any) => {
    if (!article) return;
    if (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) {
      if (e.keyCode === 83) {
        e.preventDefault();
        handleSaveArticle(e);
      } else if (e.keyCode === 66) {
        e.preventDefault();
        handleAddNewRow({ layout: "oneColumn" });
      } else if (e.keyCode === 38 || e.keyCode === 40) {
        e.preventDefault();
        const index = article.content.rows.findIndex((b: any) => b.id === focusedRow);
        if (index <= 0 && e.keyCode === 38) return;
        handleMovePosition({ id: focusedRow, direction: e.keyCode === 38 ? "up" : "down" });
        const rowElement = document.getElementById(focusedRow);
        if (rowElement) {
          const bodyRect = document.body.getBoundingClientRect();
          const elemRect = rowElement.getBoundingClientRect();
          const offset = elemRect.top - bodyRect.top;
          window.scrollTo({ top: offset - 40, behavior: "smooth" });
        }
      }
    }
  };

  useEvent("keydown", handleKeyPress);

  const handleSaveArticle = async (alsoPublish: boolean, e?: any) => {
    if (e) e.preventDefault();
    if (!article || (!dirty && !alsoPublish)) return;
    try {
      const { data } = await updateArticle({
        variables: {
          articleRef: article._id,
          articleUpdateInput: {
            title: article.title,
            tags: article.tags,
            published: alsoPublish ? true : article.published.status,
            publishedDate: article.published.date,
            private: article.private,
            // seo: { description: article.seo.description },
            content: article.content,
            thumbnail: article.thumbnail ? article.thumbnail._id : null
          }
        }
      });
      if (!data?.articleUpdate) throw new Error("Article Error");
      addNotification({ ok: 1, message: "Article updated" });
      setArticle(clone(data.articleUpdate));
      setStringRef(JSON.stringify(clone(data.articleUpdate)));
      setDirty(false);
      modalRef.current.close();
    } catch (e: any) {
      addNotification({ ok: 0, message: e.data });
    }
  };

  const handleSeoUpdate = () => refetch();

  const handleDeleteArticle = async () => {
    if (!article) return;
    deleteArticle({ variables: { articleRef: article._id } })
      .then(() => history.push("/articles"))
      .catch((e: any) => addNotification({ ok: 0, message: e.data }));
  };

  const handleEditArticleTitle = (e: any) => {
    if (!article) return;
    const articleCopy = clone(article);
    articleCopy.title = e.target.value;
    setArticle(articleCopy);
    setDirty(true);
  };

  const handleCreatableSelect = (option: any) => {
    if (!article) return;
    const articleCopy = clone(article);
    articleCopy.tags = option ? option.map((o: any) => o.value.toLowerCase()) : [];
    setArticle(articleCopy);
    setDirty(true);
  };

  const handleThumbnailChange = async (media: any) => {
    if (!article) return;
    const articleCopy = clone(article);
    if (media && media.length) articleCopy.thumbnail = media[0];
    else articleCopy.thumbnail = null;
    setArticle(articleCopy);
    setDirty(true);
  };

  const handleCheckboxChange = async (e: any) => {
    if (!article) return;
    const articleCopy = clone(article);
    if (e.target.name === "published") articleCopy.published.status = e.target.checked;
    else if (e.target.name === "private") articleCopy.private = e.target.checked;
    else if (e.target.name === "homepage") {
      articleCopy.homepage = e.target.checked;
      if (articleCopy.homepage) articleCopy.private = false;
    }
    setArticle(articleCopy);
    setDirty(true);
  };

  const handleAddNewRow = async ({ layout }: { layout: any }) => {
    if (!article) return;
    const articleCopy = clone(article);
    let columnsToAdd = 1;
    if (layout.includes("two")) columnsToAdd = 2;
    else if (layout.includes("three")) columnsToAdd = 3;
    const arrayToAdd = [];
    for (let i = 0; i < columnsToAdd; i++) arrayToAdd.push({ id: shortid.generate(), blocks: [{ id: shortid.generate(), type: null }] });
    articleCopy.content.rows.push({
      id: shortid.generate(),
      options: { fullWidth: false },
      type: rowTypes(t)[1].value,
      feed: { limit: 6, tags: [], sort: clone(sorts(t)[0].value), format: sizes[0].name },
      layout,
      columns: arrayToAdd
    });
    setArticle(articleCopy);
    setDirty(true);
  };

  const findColumn = ({ article, id }: { article: any; id: string }) => {
    let column = null;
    article.content.rows.forEach((r: any) => {
      const foundColumn = r.columns.find((c: any) => c.id === id);
      if (foundColumn) column = foundColumn;
    });
    return column;
  };

  const handleAddBlock = async ({ id }: { id: string }) => {
    if (!article) return;
    const articleCopy = clone(article);
    const column: any = findColumn({ article: articleCopy, id });
    if (!column) return addNotification({ ok: 0, message: `Column not found ${id}` });
    column.blocks.push({ type: null, id: shortid.generate() });
    setArticle(articleCopy);
    setDirty(true);
  };

  const handlePublishedDate = (dateValue: string) => {
    if (!article || !dateValue) return;
    const articleCopy = clone(article);
    articleCopy.published.date = dateValue;
    setArticle(clone(articleCopy));
    setDirty(true);
  };

  const handleDeleteRow = async ({ id }: { id: string }) => {
    if (!article) return;
    if (window.confirm(t("Are you sure you wish to delete this row?"))) {
      const articleCopy = clone(article);
      const rowIndex = articleCopy.content.rows.findIndex((r: any) => r.id === id);
      if (rowIndex === -1) return addNotification({ ok: 0, message: "Invalid row" });
      articleCopy.content.rows.splice(rowIndex, 1);
      setArticle(articleCopy);
      setDirty(true);
    }
  };

  const handleMovePosition = ({ id, direction }: { id: string; direction: string }) => {
    if (!article) return;
    const articleCopy = clone(article);
    const rowIndex = articleCopy.content.rows.findIndex((r: any) => r.id === id);
    if (direction === "down") articleCopy.content.rows.splice(rowIndex + 1, 0, articleCopy.content.rows.splice(rowIndex, 1)[0]);
    else if (direction === "up") articleCopy.content.rows.splice(rowIndex - 1, 0, articleCopy.content.rows.splice(rowIndex, 1)[0]);
    setArticle(articleCopy);
    setDirty(true);
  };

  if (article === undefined || loading) return <Loader withMargins />;
  else if (article === null) return <Four0Four />;

  if (!playlists === undefined || !article || !article.content || !article.content.rows) return <Loader withMargins />;

  document.title = article.title;
  const isPublished = article.published.status;

  if (isBelowIpad) return <Typography variant="copy">{t("The menu editor isn't available for small displays")}</Typography>;
  return (
    <div id="article">
      <ModalComponent style={{ maxWidth: "50%" }} ref={modalRef}>
        <ModalHeaderContainer>
          <Typography variant="pageTitle" tag="h2">
            {t("Settings")}
          </Typography>
          <button className="reset" type="button" onClick={() => modalRef.current.close()}>
            <i className="cg-icon-burger-close" />
          </button>
        </ModalHeaderContainer>
        <form onSubmit={(e: any) => handleSaveArticle(false, e)}>
          <div id="articleEditorModal">
            <section className="titleAndHandle">
              <Input
                variant="overZone"
                label={t("Title")}
                autoComplete="off"
                type="text"
                name="title"
                required
                placeholder={t("Title")}
                value={article.title}
                onChange={handleEditArticleTitle}
              />
              <div className="thumbnail">
                <label>
                  {t("Image thumbnail")}
                  <MediaSelector
                    extensions={["png", "jpg", "jpeg"]}
                    onSelected={handleThumbnailChange}
                    onClear={() => handleThumbnailChange(null)}
                    media={article.thumbnail}
                  />
                </label>
              </div>
              <TwoColumnGrid>
                <SelectCreatable
                  label={t("Tags")}
                  variant="overZone"
                  isMulti={true}
                  options={tags.map((l: any) => ({ label: l, value: l }))}
                  name="tags"
                  value={article.tags.map((o: string) => ({ label: o, value: o }))}
                  onChange={handleCreatableSelect}
                />

                {article.published.status ? (
                  <DatePicker
                    variant="overZone"
                    dateFormat="dd/MM/yyyy"
                    value={article.published.date}
                    label={t("Published date")}
                    onChange={handlePublishedDate}
                  />
                ) : null}
              </TwoColumnGrid>

              <Checkbox
                label={t("Privacy: visible to registered users only")}
                onChange={handleCheckboxChange}
                name="private"
                disabled={article.homepage}
                checked={article.homepage ? false : article.private || false}
              />
              <Checkbox
                label={t("Publish this article to the Eshop")}
                onChange={handleCheckboxChange}
                name="published"
                checked={article.published.status}
              />
            </section>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ButtonV2
                type="button"
                disabled={updating || deleting}
                onClick={() =>
                  window.confirm(t("Are you sure you wish to delete this article? It cannot be recovered")) && handleDeleteArticle()
                }
                variant="warning">
                {t("Delete")}
              </ButtonV2>
              <ButtonV2 type="submit" variant="primary" disabled={updating || deleting || !dirty}>
                {updating ? <Loader /> : t("Save")}
              </ButtonV2>
            </div>
          </div>
        </form>
      </ModalComponent>
      <Prompt when={dirty} message={() => t("Changes were unsaved, are you sure?")} />
      <div
        className="header"
        style={{ display: "grid", justifyContent: "space-between", gridTemplateColumns: "1fr 1fr", marginBottom: "var(--gutter)" }}>
        <div>
          <div style={{ display: "flex", alignItems: "baseline", marginBottom: "calc(var(--gutter)/2)" }}>
            <Typography variant="pageTitle" tag="h1" style={{ marginRight: "var(--gutter)" }}>
              {t("Article")}
            </Typography>
            <Link to="/editor/articles">{t("Back")}</Link>
          </div>
          <div>
            <Typography variant="copy" tag="p" style={{ marginRight: "var(--gutter)" }}>
              {article.title}
            </Typography>
          </div>
          {article.modified.length ? (
            <Typography variant="copy" level="secondary" style={{ margin: "0" }} tag="p">
              {t("Last modified by")} {article.modified[0].user?.name} {moment(article.modified[0].date).format("llll")}
            </Typography>
          ) : null}
        </div>

        <div className="buttons">
          {isPublished ? (
            <a target="_blank" rel="noopener noreferrer" href={`${uri}${article?.handle}?preview=true`}>
              <ButtonV2 variant="secondary" type="button">
                {t("Preview")} <i style={{ marginLeft: "5px" }} className="cg-icon-open-tab" />
              </ButtonV2>
            </a>
          ) : null}
          <SeoEditor config={config} onSubmit={handleSeoUpdate} addNotification={addNotification} document={article} />
          <div>
            <ButtonV2 type="submit" onClick={() => modalRef.current.open()} variant="secondary" disabled={updating || deleting}>
              {t("Settings")}
            </ButtonV2>
          </div>
          {isPublished ? (
            <div>
              <ButtonV2 type="button" variant="primary" onClick={() => handleSaveArticle(false)} disabled={updating || deleting || !dirty}>
                {updating ? <Loader /> : t("Save")}
              </ButtonV2>
            </div>
          ) : (
            <>
              {dirty ? (
                <div>
                  <ButtonV2
                    type="button"
                    variant="primary"
                    onClick={() => handleSaveArticle(false)}
                    disabled={updating || deleting || !dirty}>
                    {updating ? <Loader /> : t("Save draft") + "*"}
                  </ButtonV2>
                </div>
              ) : (
                <div>
                  <ButtonV2 type="button" variant="primary" disabled={!article.content.rows.length} onClick={() => handleSaveArticle(true)}>
                    {updating ? <Loader /> : t("Publish")}
                  </ButtonV2>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="content">
        <div className="rows">
          {article.content.rows.map((r: any, i: number) => (
            <Row
              key={i}
              row={r}
              collections={collections}
              playlists={playlists}
              lists={lists}
              setArticle={setArticle}
              article={article}
              focusedRow={focusedRow}
              setFocusedRow={setFocusedRow}
              rowsCount={article.content.rows.length}
              handleMovePosition={handleMovePosition}
              rowIndex={i}
              handleDeleteRow={handleDeleteRow}
              handleAddBlock={handleAddBlock}
              tags={tags}
              t={t}
            />
          ))}
          <LayoutProposal handleAddNewRow={handleAddNewRow} t={t} />
        </div>
      </div>
    </div>
  );
}

const Row = ({
  row,
  article,
  rowsCount,
  setArticle,
  focusedRow,
  setFocusedRow,
  rowIndex,
  handleAddBlock,
  handleDeleteRow,
  handleMovePosition,
  collections,
  playlists,
  tags,
  lists,
  t
}: {
  row: any;
  article: any;
  rowsCount: number;
  setArticle: any;
  focusedRow: any;
  setFocusedRow: any;
  rowIndex: number;
  handleAddBlock: any;
  handleDeleteRow: any;
  handleMovePosition: any;
  collections: any;
  playlists: any;
  tags: any;
  lists: any;
  t: TFunction;
}) => {
  const handleSetFocus = () => {
    setFocusedRow(row.id);
  };

  const handleCheckboxChange = (e: any) => {
    const articleCopy = clone(article);
    const rowToUpdate = articleCopy.content.rows.find((r: any) => r.id === row.id);
    if (row) {
      if (!rowToUpdate.options) rowToUpdate.options = {};
      rowToUpdate.options[e.target.name] = e.target.checked;
      setArticle(articleCopy);
    }
  };

  const focused = focusedRow === row.id;

  return (
    <div
      className={`row ${row.layout} ${focused ? "focused" : ""} ${row.options && row.options.fullWidth ? "fullWidth" : ""} ${
        row.options && row.options.noPadding ? "noPadding" : ""
      }`}
      id={row.id}
      onClick={handleSetFocus}>
      <Zone className="header">
        <div className="left">
          <Button
            variant="secondaryOverZone"
            type="button"
            disabled={rowIndex <= 0}
            className="reset"
            onClick={() => handleMovePosition({ id: row.id, direction: "up" })}>
            {t("Move up")}
          </Button>
          <Button
            variant="secondaryOverZone"
            type="button"
            disabled={rowIndex >= rowsCount - 1}
            className="reset"
            onClick={() => handleMovePosition({ id: row.id, direction: "down" })}>
            {t("Move down")}
          </Button>
          <Button variant="danger" type="button" className="reset delete" onClick={() => handleDeleteRow({ id: row.id })}>
            {t("Delete row")}
          </Button>
        </div>
        <div className="options">
          <label>
            <input name="fullWidth" onChange={handleCheckboxChange} checked={row.options?.fullWidth || false} type="checkbox" />
            {t("Full width")}
          </label>
          <label>
            <input name="noPadding" onChange={handleCheckboxChange} checked={row.options?.noPadding || false} type="checkbox" />
            {t("No padding")}
          </label>
        </div>
      </Zone>
      <div className="content">
        <div className="columns">
          {row.columns.map((c: any, i: number) => (
            <React.Fragment key={i}>
              <Column
                column={c}
                row={row}
                tags={tags}
                handleAddBlock={handleAddBlock}
                article={article}
                setArticle={setArticle}
                collections={collections}
                playlists={playlists}
                lists={lists}
                t={t}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

const Column = ({
  column,
  handleAddBlock,
  row,
  tags,
  article,
  setArticle,
  collections,
  playlists,
  lists,
  t
}: {
  column: any;
  handleAddBlock: any;
  row: any;
  article: any;
  setArticle: any;
  collections: any;
  playlists: any;
  lists: any;
  tags: string[];
  t: TFunction;
}) => {
  return (
    <div className="column">
      <BlockElements
        blocks={column.blocks}
        article={article}
        setArticle={setArticle}
        row={row}
        collections={collections}
        playlists={playlists}
        lists={lists}
        tags={tags}
        t={t}
      />
      <div className="footer">
        <Button variant="secondary" onClick={() => handleAddBlock({ id: column.id })}>
          {t("Add block")}
        </Button>
      </div>
    </div>
  );
};

const LayoutProposal = ({ handleAddNewRow, t }: { handleAddNewRow: any; t: TFunction }) => {
  return (
    <div className="layoutProposal">
      <h2>{t("Choose a row layout")}</h2>
      <div className="layouts">
        <div className="layout" onClick={() => handleAddNewRow({ layout: "oneColumn" })}>
          <div className="oneColumn" />
          <p className="description">{t("One column")}</p>
        </div>
        <div className="layout" onClick={() => handleAddNewRow({ layout: "twoColumn" })}>
          <div className="twoColumn" />
          <p className="description">{t("Two column")}</p>
        </div>
        <div className="layout" onClick={() => handleAddNewRow({ layout: "threeColumn" })}>
          <div className="threeColumn" />
          <p className="description">{t("Three column")}</p>
        </div>
      </div>
    </div>
  );
};
