import styled from "styled-components";

import { colorsAsRgbString, colorsAsRgbArray, applyOpacity } from "@common-ground-io/colors";
import { devices, getThemeProps } from "../../utils/styles";
import { BaseStyledComponentProps, LayoutType } from "../../types/styles.types";

type ThemeProps = {
  // border
  borderColor: string;
  borderColorActive: string;
  borderColorHover: string;
  borderColorDisabled: string;
  // background
  bgColor: string;
  bgColorActive: string;
  bgColorHover: string;
  bgColorDisabled: string;
  // font
  fontColor: string;
  fontColorActive: string;
  fontColorHover: string;
  fontColorDisabled: string;
};

type ThemeVariants = {
  primary: ThemeProps;
  secondary: ThemeProps;
  navigation: ThemeProps;
  warning: ThemeProps;
  success: ThemeProps;
};

type Props = BaseStyledComponentProps<ThemeProps, ThemeVariants> & {
  fullWidth?: boolean;
  isSettings?: boolean;
  form?: string;
};

const primaryVariant = {
  // border
  borderColor: colorsAsRgbString.primary,
  borderColorHover: colorsAsRgbString.primary,
  borderColorActive: "transparent",
  borderColorDisabled: "transparent",
  // background
  bgColor: colorsAsRgbString.primary,
  bgColorHover: applyOpacity(colorsAsRgbArray.primary, 0.4),
  bgColorActive: applyOpacity(colorsAsRgbArray.primary, 0.1),
  bgColorDisabled: applyOpacity(colorsAsRgbArray.primary, 0.1),
  // font
  fontColor: colorsAsRgbString.white,
  fontColorActive: colorsAsRgbString.primary,
  fontColorHover: colorsAsRgbString.white,
  fontColorDisabled: applyOpacity(colorsAsRgbArray.primary, 0.4)
};

const secondaryVariant = {
  // border
  borderColor: applyOpacity(colorsAsRgbArray.greyLight, 0.2),
  borderColorHover: colorsAsRgbString.greyLight,
  borderColorActive: colorsAsRgbString.greyDark,
  borderColorDisabled: applyOpacity(colorsAsRgbArray.grey, 0.1),
  // background
  bgColor: applyOpacity(colorsAsRgbArray.greyDark, 0.1),
  bgColorHover: applyOpacity(colorsAsRgbArray.greyDark, 0.4),
  bgColorActive: colorsAsRgbString.grey,
  bgColorDisabled: applyOpacity(colorsAsRgbArray.grey, 0.1),
  // font
  fontColor: colorsAsRgbString.grey,
  fontColorHover: colorsAsRgbString.greyLightest,
  fontColorActive: colorsAsRgbString.white,
  fontColorDisabled: applyOpacity(colorsAsRgbArray.grey, 0.4)
};

const secondaryVariantLight = {
  // border
  borderColor: colorsAsRgbString.greyLight,
  borderColorHover: colorsAsRgbString.greyOvercast,
  borderColorActive: colorsAsRgbString.greyDark,
  borderColorDisabled: applyOpacity(colorsAsRgbArray.grey, 0.1),
  // background
  bgColor: applyOpacity(colorsAsRgbArray.zone, 0.05),
  bgColorHover: colorsAsRgbString.greyLightest,
  bgColorActive: colorsAsRgbString.greyDarkest,
  bgColorDisabled: applyOpacity(colorsAsRgbArray.grey, 0.1),
  // font
  fontColor: colorsAsRgbString.greyOvercast,
  fontColorHover: colorsAsRgbString.greyDark,
  fontColorActive: colorsAsRgbString.white,
  fontColorDisabled: applyOpacity(colorsAsRgbArray.grey, 0.4)
};

const successVariant = {
  // border
  borderColor: applyOpacity(colorsAsRgbArray.greyLight, 0.2),
  borderColorHover: colorsAsRgbString.success,
  borderColorActive: "transparent",
  borderColorDisabled: applyOpacity(colorsAsRgbArray.success, 0.1),
  // background
  bgColor: applyOpacity(colorsAsRgbArray.success, 0.2),
  bgColorHover: applyOpacity(colorsAsRgbArray.success, 0.2),
  bgColorActive: colorsAsRgbString.success,
  bgColorDisabled: applyOpacity(colorsAsRgbArray.success, 0.1),
  // font
  fontColor: colorsAsRgbString.success,
  fontColorHover: colorsAsRgbString.success,
  fontColorActive: colorsAsRgbString.white,
  fontColorDisabled: applyOpacity(colorsAsRgbArray.success, 0.2)
};

const navigationVariant = {
  // border
  borderColor: applyOpacity(colorsAsRgbArray.blue, 0.2),
  borderColorHover: colorsAsRgbString.blue,
  borderColorActive: colorsAsRgbString.blue,
  borderColorDisabled: "transparent",
  // background
  bgColor: applyOpacity(colorsAsRgbArray.blue, 0.2),
  bgColorHover: applyOpacity(colorsAsRgbArray.blue, 0.1),
  bgColorActive: colorsAsRgbString.blue,
  bgColorDisabled: applyOpacity(colorsAsRgbArray.blue, 0.1),
  // font
  fontColor: colorsAsRgbString.blue,
  fontColorHover: colorsAsRgbString.blue,
  fontColorActive: colorsAsRgbString.white,
  fontColorDisabled: applyOpacity(colorsAsRgbArray.blue, 0.2)
};

const warningVariant = {
  // border
  borderColor: "transparent",
  borderColorHover: colorsAsRgbString.alert,
  borderColorActive: colorsAsRgbString.alert,
  borderColorDisabled: applyOpacity(colorsAsRgbArray.alert, 0.1),
  // background
  bgColor: applyOpacity(colorsAsRgbArray.alert, 0.2),
  bgColorHover: applyOpacity(colorsAsRgbArray.alert, 0.1),
  bgColorActive: colorsAsRgbString.alert,
  bgColorDisabled: applyOpacity(colorsAsRgbArray.alert, 0.1),
  // font
  fontColor: colorsAsRgbString.alert,
  fontColorHover: colorsAsRgbString.alert,
  fontColorActive: colorsAsRgbString.white,
  fontColorDisabled: applyOpacity(colorsAsRgbArray.alert, 0.2)
};

const layout: LayoutType<ThemeProps, ThemeVariants> = {
  light: {
    primary: primaryVariant,
    secondary: secondaryVariantLight,
    navigation: navigationVariant,
    warning: warningVariant,
    success: successVariant
  },
  dark: {
    primary: primaryVariant,
    secondary: secondaryVariant,
    navigation: navigationVariant,
    warning: warningVariant,
    success: successVariant
  }
};

export const StyledButton = styled.button<Props>`
  height: auto;
  padding: 12px 13px;
  border: 1px solid ${props => getThemeProps(layout, props).borderColor};
  border-radius: 10px;
  cursor: pointer;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  width: ${({ fullWidth }) => fullWidth && "100%"};
  background-color: ${props => getThemeProps(layout, props).bgColor};
  color: ${props => getThemeProps(layout, props).fontColor};
  transition: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${props => getThemeProps(layout, props).bgColorHover};
    border: 1px solid ${props => getThemeProps(layout, props).borderColorHover};
    color: ${props => getThemeProps(layout, props).fontColorHover};
    transition: background-color 0.15s ease-in;
  }

  &:active {
    background-color: ${props => getThemeProps(layout, props).bgColorActive};
    border: 1px solid ${props => getThemeProps(layout, props).borderColorActive};
    color: ${props => getThemeProps(layout, props).fontColorActive};
    transition: none;
  }

  &:disabled {
    background-color: ${props => getThemeProps(layout, props).bgColorDisabled};
    border: 1px solid ${props => getThemeProps(layout, props).borderColorDisabled};
    color: ${props => getThemeProps(layout, props).fontColorDisabled};
  }

  @media (${devices.tabletL}) {
    font-size: ${props => (props.isSettings ? 0 : "15px")};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  position: relative;
  height: 10px;
  width: 22px;
  @media (${devices.tabletL}) {
    margin-right: 0;
  }
`;
