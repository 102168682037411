import React from "react";
import { IconContainer, StyledButton } from "./Button.styles";
import { Link } from "react-router-dom";
import { SettingsIcon } from "../SVGIcons";

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  styleProps?: React.CSSProperties;
  type?: "submit" | "button" | "link";
  href?: string;
  fullWidth?: boolean;
  variant: "primary" | "success" | "secondary" | "navigation" | "warning";
  disabled?: boolean;
  isSettings?: boolean;
  form?: string;
  datatestid?: string;
  id?: string;
};

export const Button: React.FC<Props> = ({
  children,
  fullWidth,
  onClick,
  type = "button",
  variant = "primary",
  styleProps,
  disabled,
  href,
  form,
  id,
  datatestid,
  isSettings = false
}) => {
  return (
    <>
      {type === "link" ? (
        <Link href={href ?? "/"} id={id} to={href ?? "/"} style={{ textDecoration: "none" }}>
          <StyledButton
            fullWidth={fullWidth}
            variant={isSettings ? "secondary" : variant}
            onClick={onClick}
            style={styleProps}
            disabled={disabled}
            type="button"
            data-testid={datatestid}
            isSettings={isSettings}>
            {isSettings && (
              <IconContainer>
                <SettingsIcon />
              </IconContainer>
            )}
            {children}
          </StyledButton>
        </Link>
      ) : (
        <StyledButton
          fullWidth={fullWidth}
          variant={isSettings ? "secondary" : variant}
          type={type}
          form={form}
          id={id}
          onClick={onClick}
          style={styleProps}
          disabled={disabled}
          data-testid={datatestid}
          isSettings={isSettings}>
          {isSettings && (
            <IconContainer>
              <SettingsIcon />
            </IconContainer>
          )}
          {children}
        </StyledButton>
      )}
    </>
  );
};
