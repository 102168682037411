import { useState, forwardRef, useImperativeHandle, ReactNode } from "react";
import Modal from "react-modal";
import { colorsAsRgbString } from "@common-ground-io/colors";
import Color from "color-js";
import { GlobalStore } from "../stores/global";
import merge from "deepmerge";
import { EThemes } from "../types/globals";
Modal.setAppElement("#root");

const getModalStyle = (theme: EThemes, style: any) => {
  const overlayColor = Color(theme === EThemes.light ? colorsAsRgbString.greyLightest : colorsAsRgbString.greyDark).setAlpha(0.7);
  const contentColor = Color(theme === EThemes.light ? colorsAsRgbString.greyLightest : colorsAsRgbString.greyDarkest).setAlpha(1);

  const customStyles = {
    overlay: {
      background: overlayColor.toRGB(),
      zIndex: "2"
    },
    content: merge(
      {
        top: "50%",
        left: "50%",
        right: "auto",
        color: theme === "dark" ? colorsAsRgbString.grey : colorsAsRgbString.greyDark,
        bottom: "auto",
        marginRight: "-50%",
        minWidth: "40%",
        maxWidth: "80%",
        height: "auto",
        maxHeight: "70%",
        border: "none",
        borderRadius: "10px",
        transform: "translate(-50%, -50%)",
        background: contentColor.toString()
      },
      style
    )
  };

  return customStyles;
};

interface Props {
  children: ReactNode;
  style: any;
  onClose?: any;
  className?: string;
}

export interface PropsToForward {
  open: any;
  close: any;
  modalIsOpen: any;
}

const ModalComponent = forwardRef<PropsToForward, Props>((props: Props, ref) => {
  const theme = GlobalStore.useState(c => c.theme);
  const [modalIsOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  useImperativeHandle(ref, (): PropsToForward => ({ open, close, modalIsOpen }), []);

  return (
    <Modal
      {...props}
      id={`modal-${theme}`}
      isOpen={modalIsOpen}
      onRequestClose={() => {
        setIsOpen(false);
        props.onClose && props.onClose();
      }}
      style={getModalStyle(theme as EThemes, props.style || {})}
    />
  );
});

export default ModalComponent;
