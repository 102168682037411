import { PlacesType, Tooltip } from "react-tooltip";
import { FaQuestionCircle } from "react-icons/fa";
import { CSSProperties } from "styled-components";
import { GlobalStore } from "../../stores/global";
import { colorsAsRgbString } from "@common-ground-io/colors";

export default function TooltipComponent({
  Icon,
  children,
  style,
  place = "left"
}: {
  style?: CSSProperties;
  className?: string;
  Icon?: any;
  children?: any;
  place?: PlacesType;
}) {
  const theme = GlobalStore.useState(c => c.theme);

  const id = Math.random().toString(36).substring(2, 8);

  const toolTipStyles = {
    opacity: 1,
    backgroundColor: theme === "light" ? colorsAsRgbString.greyLighter : colorsAsRgbString.greyDarker,
    padding: "10px",
    borderRadius: "10px",
    color: "inherit"
  };

  return (
    <div className="">
      <div data-tooltip-id={id} data-tooltip-place={place} style={style || { margin: "0px 5px" }}>
        {Icon ? <Icon /> : <FaQuestionCircle />}
      </div>
      <Tooltip
        id={id}
        place={place}
        opacity={1}
        border={`1px solid ${theme === "light" ? colorsAsRgbString.greyLight : colorsAsRgbString.greyDarker}`}
        style={toolTipStyles}>
        {children}
      </Tooltip>
    </div>
  );
}
